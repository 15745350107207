<template>
    <section>
        Aqui va la ubicacion
    </section>
</template>

<script>
export default {
    data(){
        return {

        }
    }
}   
</script>